import React from "react"
import styled from "styled-components"
import { media } from './Styles'

const TeamVideo = ({ data, text }) => {

    return (
        <StyledArticle>
            <div className='wrapper'>
                <div className='news-image'>
                    <iframe src={data} title="description"></iframe>
                </div>
                <section className='container'>
                    <div className='content'>
                        <h4 className="text" dangerouslySetInnerHTML={{ __html: text }}/>
                    </div>
                </section>
            </div>
        </StyledArticle>
    )
}

export default TeamVideo

const StyledArticle = styled.div`
    margin: 0 7.3%;
    position: relative;
    min-height: 60vh;

    iframe{
        width: 100%;
        height: 100%;
    }

    .wrapper{
        position: relative;
        min-height: 60vh;

        ${media.xl`
            min-height: unset;
            flex-direction:column;
        `}

        .news-image{
            position: absolute;
            width: 60%;
            height: 100%;
            right: 0;
            top: 0;
            margin-bottom: -1vh;
            z-index:0;

            ${media.xl`
                position:unset;
                width:100%;
            `}

            .gatsby-image-wrapper{
                height:100%;

                ${media.xl`
                    max-height:50vh;
                `}
            }            
        }

        .container{
            width: 50%;
            position: absolute;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            top: 50%;
            transform: translate(0,-50%);

            ${media.xl`
                padding:0;
                width:100%;
            `}

            .content{
                width:100%;
                text-align:left;
                display: flex;
                flex-direction: column;
                gap: 2rem;
                justify-content: center;
                background-color: #212121F2;
                color:#fff;
                padding: 6rem 5%;
                min-height: 40vh;

                ${media.smarttv`
                    gap:0;
                `}

                ${media.xl`
                    padding:5rem 5%;
                    min-height:unset;
                `}

                ${media.m`
                    padding:3rem 5%;
                `}

                h4{
                    font-size: 1.4rem;

                    ${media.s`
                        font-size: 1.2rem;
                    `}
                }
            }
        }
        @media (max-width: 990px){
            .wrapper{
                position: relative;
                display: grid;
            }
            .news-image{
                position: relative;
                min-height: 40vh;
                height: 35vh;
            }
            .container{
                position: relative;
                top: 60%;
            }
        }        
    }
`
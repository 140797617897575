import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Sidebar from "../components/homeMenu/sideBar"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import MainBanner from '../components/MainBanner'
import TextBanner from '../components/TextBanner'
import Team from "../components/Team"
import Video from "../components/TeamVideo"
import { toggleWhiteSidebar } from "../state/app"
import { InView } from 'react-intersection-observer'
import { connect } from 'react-redux'

const EquipaPage = ({ dispatch, location }) => {
  const { t } = useTranslation()

  var dataEquipa = t("equipa", { returnObjects: true })
  var dataGlobal = t("global", { returnObjects: true })

  return (
    <Layout
      data={dataGlobal}
      location={location}
    >
      <Seo title={dataEquipa.title} />
      <Sidebar
        content={dataGlobal.sidebar}
        projetosPortfolio={t("portfolio", { returnObjects: true }).projetos}
        projetosVenda={t("projetosVenda", { returnObjects: true }).projetos}
        whiteMobileNavbar
      />
      {/* CHANGE SIDE BAR COLOR TO WHITE */}
      <InView as="div" threshold={0.5} onChange={inView => dispatch(toggleWhiteSidebar(inView))}>
        <MainBanner data={dataEquipa.menu} />
      </InView>
      <TextBanner data={dataEquipa.content} />

      <Video data={dataEquipa.video.url} text={dataEquipa.video.text} />

      <Team data={dataEquipa.team} />
    </Layout>
  )
}

export default connect()(EquipaPage)

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["equipa", "global", "projetosVenda", "portfolio"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`